import React from "react";
import CommandLink from '../commandLink/CommandLink'
import MenuItem from '../menuItem/MenuItem'
import './appWelcomeCss.css' ;
import formuleLogo from '../../data/appData/appImages/carteCatalogue/230px/formule.png'
import pizzaTLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_t.png'
import pizzaCLogo from '../../data/appData/appImages/carteCatalogue/230px/pizza_cf.png'
import assietteLogo from '../../data/appData/appImages/carteCatalogue/230px/assiette.png'
import tacosLogo from '../../data/appData/appImages/carteCatalogue/230px/tacos2.png'
import sandwichLogo from '../../data/appData/appImages/carteCatalogue/230px/sandwich.png'
import burgerLogo from '../../data/appData/appImages/carteCatalogue/230px/burger.png'
import texMexLogo from '../../data/appData/appImages/carteCatalogue/230px/texMex.png'
import saladeLogo from '../../data/appData/appImages/carteCatalogue/230px/salade.png'
import dessertLogo from '../../data/appData/appImages/carteCatalogue/230px/dessert.png'
import boissonLogo from '../../data/appData/appImages/carteCatalogue/230px/boisson.png'
import enfantLogo from '../../data/appData/appImages/carteCatalogue/230px/enfant.png'

import sushiLogo from '../../data/appData/appImages/carteCatalogue/230px/sushi.png'
import gratinLogo from '../../data/appData/appImages/carteCatalogue/230px/gratin.png'
import patesLogo from '../../data/appData/appImages/carteCatalogue/230px/pates.png'
import tajineLogo from '../../data/appData/appImages/carteCatalogue/230px/tajine.png'
import poissonLogo from '../../data/appData/appImages/carteCatalogue/230px/poisson.png'
import soupeLogo from '../../data/appData/appImages/carteCatalogue/230px/soupe.png'


function Content(){

/*
    const menuItemTable =[
        [formuleLogo, "https://www.walima-eat.com/#/etablissements/51100/8/site/category1", "NOS FORMULES"],
        [pizzaTLogo, "https://www.walima-eat.com/#/etablissements/51100/8/site/category1", "NOS PIZZAS T"],
        [pizzaCLogo, "https://www.walima-eat.com/#/etablissements/51100/8/site/category1", "NOS PIZZAS C F"],
        [assietteLogo, "https://www.walima-eat.com/#/etablissements/51100/8/site/category4", "NOS PLATS"],
        [tacosLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category5', "NOS TACOS"],
        [sandwichLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category1', "NOS SANDWICHS"],
        [burgerLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category7', "NOS BURGERS"],
        [texMexLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category8', "NOS TEXMEX"],
        [enfantLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category10',"NOS MENUS ENFANT"],
        [saladeLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category9',"NOS SALADES"],
        [dessertLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category11',"NOS DESSERTS"],
        [boissonLogo, 'https://www.walima-eat.com/#/etablissements/51100/8/site/category12',"NOS BOISSONS"],
    ];*/

    const menuItemTable =[
        [tajineLogo, "#", "TAJINE"],
        [poissonLogo, "#", "POISSON"],
        [pizzaTLogo, "#", "PIZZAS T"],
        [pizzaCLogo, "#", "PIZZAS C F"],

        [assietteLogo, "#", "PLATS"],


        [gratinLogo, "#", "GRATINS"],
        [sushiLogo, "#", "SUSHI"],
        [soupeLogo, "#", "SOUPES"],

        [patesLogo, "#", "PATES"],



        [tacosLogo, '#', "TACOS"],
        [sandwichLogo, '#', "SANDWICHS"],
        [burgerLogo, '#', "BURGERS"],
        [enfantLogo, '#',"ENFANT"],
        [saladeLogo, '#',"SALADES"],
        [dessertLogo, '#',"DESSERTS"],
        [boissonLogo, '#',"BOISSONS"],
    ]; 


    return(

        <div className='content1Div'>
            <CommandLink></CommandLink>
            

            <div className="centePerCentWidthAndFloatContainer">
                <div className='rowContainer rowContainerResp'>
                    
                    <div>
                        {menuItemTable.map((item, index) => {
                                return (
                                    <MenuItem menuItemLogo={item[0]}  menuItemLink={item[1]} itemTitle={item[2]} key={index}></MenuItem>
                                );

                            })}
                    </div>
                
                </div>
            </div>
            

        </div>

    );
}

export default Content