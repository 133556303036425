import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMousePointer} from "@fortawesome/fontawesome-free-solid"
import {faPhone} from "@fortawesome/fontawesome-free-solid"
import './commandLinkCss.css'

function CommandLink(){

    return(
        <div className="telCommandDiv">
		<a href="https://www.walima-eat.com/#/etablissements/51100/8/site/category1">
			<div className="telCommandDivMarge telCommandDivMargeResp"  >
				<div id="clignDiv"  >
				
					<FontAwesomeIcon icon={faPhone}  />   08 08 50 24 00
				
				
						
				{/*<span className="commandEnLigneDiv"><FontAwesomeIcon icon={faMousePointer} /> Commander en ligne</span> <span className="paiementDiv">&nbsp; et payer à la livraison</span>*/}
					
					
				</div>	
			</div>
		</a>
	</div>
    );
}
export default CommandLink